<template>
  <TTView>
    <VRow>
      <VCol>
        <AssessmentForm
          :entity="skillAssessment"
          :loading="loading"
          :skills="skills"
          :read-only="['skillId']"
          @update:name="skillAssessment.name = $event"
          @update:description="skillAssessment.description = $event"
          @update:settings:cooldown="skillAssessment.settings.cooldown = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import AssessmentForm from '../../components/expert-assessment/AssessmentForm.vue';

export default {
  name: 'AssessmentEdit',

  components: {
    AssessmentForm,
  },

  data() {
    return {
      loading: false,
      skills: [],
      skillAssessment: {
        id: null,
        name: null,
        description: null,
        skillId: null,
        status: null,
        settings: {
          cooldown: 0,
        },
      },
    };
  },

  computed: {
    assessmentId() {
      return this.$route.params.assessmentId;
    },
    skillId() {
      return this.$route.params.skillId;
    },
  },
  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { assessmentId } = this;
        const data = { id: assessmentId };

        const { skills } = await this.$di.api.CompetencyMatrix.SkillsIndex();
        const { skillAssessment } = await this.$di.api.ExpertAssessment.skillAssessmentGet({ data });

        this.skills = skills;
        this.skillAssessment = skillAssessment;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    async handleSubmit() {
      try {
        this.loading = true;

        const { assessmentId, skillAssessment } = this;
        const data = { id: assessmentId, skillAssessment };
        delete skillAssessment.id;
        delete skillAssessment.status;
        delete skillAssessment.skillId;

        await this.$di.api.ExpertAssessment.skillAssessmentUpdate({ data });

        this.$router.go(-1);
        // TODO: показать уведомление
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
