<template>
  <!-- FIXME: временно грязное решение -->
  <!-- eslint-disable vue/no-mutating-props -->
  <VCard
    :loading="loading"
    :disabled="loading"
  >
    <VCardTitle>
      {{ normalizedTitle }}
    </VCardTitle>

    <VCardText>
      <VForm>
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.name"
                label="Имя"
                name="assessmentName"
                persistent-hint
                hint="имя опроса"
                @input="$emit('update:name', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextarea
                :value="entity.description"
                label="Описание"
                name="assessmentDescription"
                persistent-hint
                hint="описание опроса"
                @input="$emit('update:description', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.settings.cooldown"
                type="number"
                label="cooldown"
                name="settingsCooldown"
                persistent-hint
                hint="cooldown"
                @input="$emit('update:settings:cooldown', Number($event))"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VAutocomplete
                v-model="entity.skillId"
                :items="skills"
                :search-input.sync="searchSkills"
                :disabled="isReadOnly('skillId')"
                :filter="customFilter"
                item-text="name"
                item-value="id"
                label="Навык"
                name="skillId"
                persistent-hint
                hint="навык опроса"
                @input="$emit('update:skillId', $event)"
              >
                <template #selection="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>

                <template #item="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>
              </VAutocomplete>
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        @click="handleSubmit"
      >
        {{ normalizedSubmit }}
      </VBtn>
    </VCardActions>
  </VCard>
  <!-- eslint-enable vue/no-mutating-props -->
</template>

<script>
export default {
  name: 'AssessmentForm',

  props: {
    entity: {
      type: Object,
      default: () => ({}),
    },

    skills: {
      type: Array,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    readOnly: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      searchSkills: null,
    };
  },

  computed: {
    normalizedTitle() {
      let title = 'Создание опроса';

      if (this.entity.id !== null && this.entity.id !== undefined) {
        title = 'Редактирование опроса';
      }

      return title;
    },

    normalizedSubmit() {
      let title = 'Добавить';

      if (this.entity.id !== null) {
        title = 'Сохранить';
      }

      return title;
    },
  },

  watch: {
    searchSkills() {
      this.$emit('update:searchSkills', this.searchSkills);
    },
  },

  methods: {
    customFilter(item, queryText) {
      const name = item.name.toLowerCase();
      const uuid = item.id.toLowerCase();
      const searchText = queryText.toLowerCase();

      return name.indexOf(searchText) > -1 || uuid.indexOf(searchText) > -1;
    },
    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      this.$emit('submit', this.entity);
    },
    isReadOnly(key) {
      return this.readOnly.includes(key);
    },
  },
};
</script>
